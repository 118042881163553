
import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


export const staffCode = Cookies.get('hsbcspringeqrbcd')?CryptoJS.AES.decrypt(Cookies.get('hsbcspringeqrbcd'), 'hsbcspring').toString(CryptoJS.enc.Utf8):'null'

export const Token = Cookies.get('hsbcspringeqrbtk')?CryptoJS.AES.decrypt(Cookies.get('hsbcspringeqrbtk'), 'hsbcspring').toString(CryptoJS.enc.Utf8):'null'


export const Usertype = Cookies.get('usertype')?Cookies.get('usertype'):'null'

export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 




export  const ServerUrl = 'https://backend.nbsparebank.com/controller';
export  const PictureUrl = 'https://backend.nbsparebank.com/images';
export const DatabaseName = "nbsparebank_db"  
export  const FrontEndUrl = 'https://backend.nbsparebank.com';   




